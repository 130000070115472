import React, { useMemo, useState } from 'react'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { SignatairesListeProceduresModel } from '../../listeProcedures/types/filtresListeProceduresModel'
import CustomTable from '../../../components/table/Table'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { enteteTableauDetailSignatairesEnum, statutSignataireEnum } from '../enums/detailProcedureEnum'
import { Box } from '@mui/material'
import Loader from '../../../components/loader/Loader'
import SvgDownload from '../../../icons/SvgDownload'
import { gecinaBrownMain } from '../../../theme'
import axios from 'axios'
import liens from '../../../enum/liens'
import { filtresListeProceduresResponseEnum } from '../../listeProcedures/enums/listeProceduresEnum'
import { downloadBlob } from '../../../utils/fileUtils'
import { FetchMethodEnum, FetchResponseType } from '../../../enum/fetchMethodEnum'
import { typeSignataire } from '../../creationProcedure/types/ajoutSignatairePopinModel'

interface AcceptingProps {
	signataires: SignatairesListeProceduresModel[]
	procedureId: string
}

type DetailSignatairesProps = AcceptingProps

const DetailSignataires: React.FC<DetailSignatairesProps> = (
	{
		signataires,
		procedureId
	}
) => {
	const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false)

	const telechargerPreuve = (signataire: SignatairesListeProceduresModel) => {
		setIsLoadingDownload(true)
		axios({
			url: `${liens.telechargementPreuve}/${procedureId}/${signataire[filtresListeProceduresResponseEnum.id]}`,
			method: FetchMethodEnum.get,
			responseType: FetchResponseType.blob
		}).then((response) => {
			// Format filename : [firstname]_[lastname]_preuve.pdf
			const fileName = `${signataire[filtresListeProceduresResponseEnum.firstName]}_${signataire[filtresListeProceduresResponseEnum.lastName]}_preuve.pdf`
			downloadBlob(response.data, fileName)
		})
			.finally(() => setIsLoadingDownload(false))
	}

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauDetailSignatairesEnum.lastName,
					id: getKeyFromEnumValue<enteteTableauDetailSignatairesEnum>(enteteTableauDetailSignatairesEnum, enteteTableauDetailSignatairesEnum.lastName)
				},
				{
					label: enteteTableauDetailSignatairesEnum.firstName,
					id: getKeyFromEnumValue<enteteTableauDetailSignatairesEnum>(enteteTableauDetailSignatairesEnum, enteteTableauDetailSignatairesEnum.firstName)
				},
				{
					label: enteteTableauDetailSignatairesEnum.mobilePhone,
					id: getKeyFromEnumValue<enteteTableauDetailSignatairesEnum>(enteteTableauDetailSignatairesEnum, enteteTableauDetailSignatairesEnum.mobilePhone)
				},
				{
					label: enteteTableauDetailSignatairesEnum.email,
					id: getKeyFromEnumValue<enteteTableauDetailSignatairesEnum>(enteteTableauDetailSignatairesEnum, enteteTableauDetailSignatairesEnum.email)
				},
				{
					label: enteteTableauDetailSignatairesEnum.type,
					id: getKeyFromEnumValue<enteteTableauDetailSignatairesEnum>(enteteTableauDetailSignatairesEnum, enteteTableauDetailSignatairesEnum.type)
				},
				{
					label: enteteTableauDetailSignatairesEnum.status,
					id: getKeyFromEnumValue<enteteTableauDetailSignatairesEnum>(enteteTableauDetailSignatairesEnum, enteteTableauDetailSignatairesEnum.status),
					getFullItem: true,
					render: (signataire: SignatairesListeProceduresModel) => {
						return signataire[filtresListeProceduresResponseEnum.status] === statutSignataireEnum.signed
						&& signataire[filtresListeProceduresResponseEnum.type] === typeSignataire.Validateur ?
							<>Validé</>
							:
							<>{signataire[filtresListeProceduresResponseEnum.status]}</>
					}
				},
				{
					label: enteteTableauDetailSignatairesEnum.proof,
					id: enteteTableauDetailSignatairesEnum.proof,
					getFullItem: true,
					size: '8%',
					render: (proof: SignatairesListeProceduresModel) =>
						proof[filtresListeProceduresResponseEnum.status] === statutSignataireEnum.signed
						&& proof[filtresListeProceduresResponseEnum.type] === typeSignataire.Signataire ? (
								<Box sx={{
									width: 'fit-content',
									'& svg': {
										marginLeft: 0
									}
								}}>
									{isLoadingDownload ?
										<Loader size={30} sx={{ mb: 0 }} />
										:
										<SvgDownload
											color={gecinaBrownMain}
											width={37}
											height={36}
											style={{ cursor: 'pointer' }}
											onClick={() => telechargerPreuve(proof)}
										/>
									}
								</Box>
							) : (
								<></>
							)
				}
			]
		), [isLoadingDownload]
	)

	return (
		<CustomTable
			id="detailSignataires"
			titre="Signataires"
			headRows={entetesTableau}
			rows={remplissageLigneSelonEnteteTableau<SignatairesListeProceduresModel>(signataires, entetesTableau)}
			hasPagination={false}
		/>
	)
}

export default DetailSignataires