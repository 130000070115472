import React, { useContext } from 'react'
import { Box } from '@mui/material'
import CustomTable from '../../../components/table/Table'
import { capitalizeFirstLetter, getKeyFromEnumValue } from '../../../utils/utils'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { HeadRow } from '../../../components/table/types/tableModel'
import { enteteTableauListeSignataireEnum } from '../enums/creationProcedureEnum'
import { SignataireValidateurModel } from '../types/CreationProcedureModel'
import { echangerPositionSignataire, mapperSignatairesPourListe } from '../utils/creationProcedureUtils'
import { gecinaBrownMain } from '../../../theme'
import SvgTrash from '../../../icons/SvgTrash'
import SvgFlecheVersBas from '../../../icons/SvgFlecheVersBas'
import SvgFlecheVersHaut from '../../../icons/SvgFlecheVersHaut'
import { CreationProcedureContext } from '../CreationProcedure'
import { champsSignataireEnum } from './popinAjoutSignataire/enums/champsSignataireEnum'
import { log } from 'node:util'


const ListeSignataires = () => {
	const { setFormValues, formValues } = useContext(CreationProcedureContext)

	const entetesTableau: HeadRow[] =
		[
			{
				label: enteteTableauListeSignataireEnum.nom,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.nom)
			},
			{
				label: enteteTableauListeSignataireEnum.prenom,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.prenom)
			},
			{
				label: enteteTableauListeSignataireEnum.telephone,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.telephone)
			},
			{
				label: enteteTableauListeSignataireEnum.courriel,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.courriel)
			},
			{
				label: enteteTableauListeSignataireEnum.type,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.type),
				render: (type: string) => (
					<span>{capitalizeFirstLetter(type)}</span>
				)
			},
			{
				label: enteteTableauListeSignataireEnum.ordre,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.ordre),
				render: (ordre: number) => (
					<>
						<span>{ordre}</span>
						{
							formValues.signersValidators.length > 1 ?
								ordre === 1 ?
									formValues.signersValidators[0][champsSignataireEnum.type] === formValues.signersValidators[1][champsSignataireEnum.type] ?
										<SvgFlecheVersBas style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, 0, 1)} />
										:
										<></>
									:
									ordre === formValues.signersValidators.length ?
										formValues.signersValidators[ordre - 2][champsSignataireEnum.type] === formValues.signersValidators[ordre - 1][champsSignataireEnum.type] ?
											<SvgFlecheVersHaut style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, ordre - 1, ordre - 2)} />
											:
											<></>
										:
										<>
											{
												formValues.signersValidators[ordre - 1][champsSignataireEnum.type] === formValues.signersValidators[ordre][champsSignataireEnum.type] ?
													<SvgFlecheVersBas style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, ordre - 1, ordre)} />
													:
													<></>
											}
											{
												formValues.signersValidators[ordre - 2][champsSignataireEnum.type] === formValues.signersValidators[ordre - 1][champsSignataireEnum.type] ?
													<SvgFlecheVersHaut style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => echangerPositionSignataire(setFormValues, formValues, ordre - 1, ordre - 2)} />
													:
													<></>
											}
										</>
								:
								<></>
						}
					</>
				)
			},
			{
				label: enteteTableauListeSignataireEnum.supprimer,
				id: getKeyFromEnumValue<enteteTableauListeSignataireEnum>(enteteTableauListeSignataireEnum, enteteTableauListeSignataireEnum.supprimer),
				render: (index: number) => (
					<SvgTrash style={{ cursor: 'pointer' }} color={gecinaBrownMain} onClick={() => setFormValues({ ...formValues, signersValidators: formValues.signersValidators.filter((value, indexFilter) => index !== indexFilter) })} />)
			}
		]

	if (formValues.signersValidators.length === 0) {
		return <></>
	}

	return (
		<Box sx={{ width: '100%' }}>
			<CustomTable
				id="listeSignataires"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<SignataireValidateurModel>(mapperSignatairesPourListe(formValues.signersValidators), entetesTableau)}
				hasPagination={false}
			/>
		</Box>
	)
}

export default ListeSignataires
