import React from 'react'
import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from '@mui/material'
import { TableType } from './types/tableModel'
import { orderEnum } from './enums/orderEnum'
import { fontFamilyMonserrat, gecinaBlueMain, gecinaBrownMain, WHITE } from '../../theme'
import { getKeyFromEnumValue } from '../../utils/utils'
import { enteteTableauListeSuiveursEnum } from '../../modules/creationProcedure/enums/creationProcedureEnum'

const CustomTable: React.FC<TableType> = (
	{
		id,
		headRows,
		rows,
		defaultOrderBy,
		defaultOrder,
		tableContainerStyle,
		hasPagination = true,
		rowBackgroundColor,
		tableStyle,
		total = 0,
		auChangementDePage,
		page = 0,
		auCliqueSurLigne,
		titre,
		hasSort = true,
		borderBottom = false,
		hideHeaders = false
	}
) => {
	const [order, setOrder] = React.useState<orderEnum>(defaultOrder ?? orderEnum.asc)
	const [orderBy, setOrderBy] = React.useState<string | undefined>(
		defaultOrderBy ?? undefined
	)
	const rowsPerPage = React.useMemo(() => 20, [])

	const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
		const isAsc = orderBy === property && order === orderEnum.asc
		setOrder(isAsc ? orderEnum.desc : orderEnum.asc)
		setOrderBy(property)
	}

	const handleChangePage = (event: any, pageActuelle: number) => {
		auChangementDePage && auChangementDePage(pageActuelle + 1)
	}

	// Tri par colonne
	const rowsDataOrdered = rows.sort((a: object, b: object) => {
		if (a[orderBy as keyof typeof a] < b[orderBy as keyof typeof b]) {
			return order === orderEnum.asc ? -1 : 1
		}
		if (a[orderBy as keyof typeof a] > b[orderBy as keyof typeof b]) {
			return order === orderEnum.asc ? 1 : -1
		}
		return 0
	})

	// Pagination.
	const rowsData =
		rowsPerPage !== -1
			? rowsDataOrdered
			: rows

	return (
		<Grid
			container
			item
			xs={12}
			sx={{
				marginTop: '50px',
				...tableContainerStyle
			}}
		>
			{titre && (
				<Box
					component="div"
					sx={{ width: '100%' }}
				>
					<Box
						component="h1"
						sx={{
							m: 0,
							fontFamily: 'BlackerDisplay',
							color: gecinaBlueMain,
							fontSize: '28px'
						}}
					>
						{titre}
					</Box>
				</Box>
			)}

			<TableContainer>
				<Table sx={{ minWidth: 700, ...tableStyle }}>
					{!hideHeaders && (
						<TableHead>
							<TableRow>
								{headRows.map((headRow) => (
									<TableCell
										key={headRow.id}
										sortDirection={orderBy === headRow.id ? order : false}
										sx={[
											{
												textAlign: 'left',
												padding: '10px',
												color: gecinaBlueMain,
												fontSize: 16,
												fontWeight: 700,
												fontFamily: fontFamilyMonserrat
											},
											orderBy === headRow.id ? {
												borderBottom: `3px solid ${gecinaBlueMain}`
											} : {
												borderBottom: `1px solid ${gecinaBlueMain}`
											},
											!!headRow.size ? { width: headRow.size } : {}
										]}
									>
										{headRow.id !== getKeyFromEnumValue<enteteTableauListeSuiveursEnum>(enteteTableauListeSuiveursEnum, enteteTableauListeSuiveursEnum.supprimer) && hasSort ?
											<TableSortLabel
												active={orderBy === headRow.id}
												direction={orderBy === headRow.id ? order : orderEnum.asc}
												onClick={createSortHandler(headRow.id)}
											>
												<Box
													component="strong"
												>
													{headRow.label}
												</Box>
											</TableSortLabel>
											:
											<Box
												component="strong"
											>
												{headRow.label}
											</Box>
										}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
					)}
					<TableBody>
						{rowsData.map((row, index) => (
							<TableRow
								key={`${id}-table-row-${page}-${index}`}
								sx={{
									backgroundColor: rowBackgroundColor,
									color: gecinaBlueMain,
									cursor: auCliqueSurLigne ? 'pointer' : 'default',

									':hover': {
										backgroundColor: auCliqueSurLigne ? gecinaBlueMain : 'inherit',
										color: auCliqueSurLigne ? gecinaBrownMain : gecinaBlueMain,
										'& svg': {
											backgroundColor: auCliqueSurLigne ? gecinaBlueMain : 'inherit',
											color: auCliqueSurLigne ? gecinaBrownMain : gecinaBlueMain
										}
									},

									'& svg': {
										marginLeft: '10px'
									}
								}}
								onClick={() => auCliqueSurLigne ? auCliqueSurLigne(row) : undefined}
							>
								{Object.values(row).map((value: string, indexValue) => (
									<TableCell
										key={`table-row-${value}-${page}-${indexValue}`}
										sx={[
											{
												textAlign: 'left',
												padding: '10px',
												borderBottom: borderBottom ? `1px solid ${gecinaBlueMain}` : '0px',
												fontSize: 14,
												fontWeight: 500,
												color: 'inherit',
												fontFamily: fontFamilyMonserrat
											},
											!!headRows[indexValue].size ? { width: headRows[indexValue].size } : {}
										]}
									>
										{value}
									</TableCell>
								))}
							</TableRow>
						))}
					</TableBody>
					{hasPagination && rowsData.length > 0 && (
						<TableFooter>
							<TableRow>
								<TablePagination
									rowsPerPageOptions={[rowsPerPage]}
									colSpan={Object.keys(rows[0]).length / 2}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									sx={{
										borderBottom: 'none'
									}}
									SelectProps={{
										inputProps: {
											'aria-label': 'Lignes par page'
										},
										native: true
									}}
									labelRowsPerPage="Lignes par page :"
									labelDisplayedRows={(recapTableau) => `${recapTableau.from}-${recapTableau.to} sur ${recapTableau.count}`}
									onPageChange={handleChangePage}
								/>
							</TableRow>
						</TableFooter>
					)}
				</Table>
			</TableContainer>
		</Grid>
	)
}

export default CustomTable
