import React, { Dispatch, SetStateAction, useState } from 'react'
import { Box, Grid } from '@mui/material'
import Dialog from '../../../../components/dialog/Dialog'
import { DialogCommonProps } from '../../../../components/dialog/types/dialogModel'
import EntetePopinAjoutSignataire from './components/EntetePopinAjoutSignataire'
import { ongletsAjoutSignataire } from '../../types/ajoutSignatairePopinModel'
import FormSignataireInterne from './components/FormSignataireInterne'
import FormSignataireExterne from './components/FormSignataireExterne'

type PopinAjoutSignataireProps = DialogCommonProps

const PopinAjoutSignataire: React.FC<PopinAjoutSignataireProps> = ({ onCloseDialog, open }) => {
	const [ongletAjoutSignataireActif, setOngletAjoutSignataireActif] =
		useState<string>(ongletsAjoutSignataire.interne)

	return (
		<Dialog
			onCloseDialog={() => {
				onCloseDialog()
				setOngletAjoutSignataireActif(ongletsAjoutSignataire.interne)
			}}
			open={open}
			fullScreen={false}
			withPadding={false}
			maxWidth={false}
		>
			<Box
				justifyContent="center"
				sx={{
					width: 785,
					height: ongletAjoutSignataireActif === ongletsAjoutSignataire.externe ? 550 : 630,
					ml: '40px',
					mr: '40px'
				}}
			>
				<EntetePopinAjoutSignataire onCloseDialog={onCloseDialog} ongletActif={ongletAjoutSignataireActif} setOngletActif={setOngletAjoutSignataireActif} />

				<Grid container item xs={12} justifyContent="space-evenly" sx={{ height: 122, mt: '20px', mb: '20px' }}>
					{
						ongletAjoutSignataireActif === ongletsAjoutSignataire.interne ?
							<FormSignataireInterne />
							:
							<FormSignataireExterne />
					}
				</Grid>
			</Box>
		</Dialog>
	)
}

export default PopinAjoutSignataire