// Enumération des champs du formulaire pour filtrer les procédures
export enum filtresListeProceduresFieldsEnum {
	nomProcedure = 'nomProcedure',
	statut = 'statut',
	cursor = 'cursor'
}

// Enumération des statuts des procédures
export enum statutListeProceduresEnum {
	all = 'Tous',
	draft = 'Brouillon',
	ongoing = 'En cours',
	approval = 'Approbation',
	done = 'Terminée',
	expired = 'Expirée',
	rejected = 'Refusée'
}

// Enumération des clés du POST des filtres de récupération de la liste des procédures
export enum filtresListeProceduresPostEnum {
	seeAll = 'seeAll',
	name = 'name',
	status = 'status',
	before = 'before',
	after = 'after',
	cursor = 'cursor'
}

// Enum pour les clés de la réponse de la récupération de la liste des procédures
export enum filtresListeProceduresResponseEnum {
	list = 'list',

	// Éléments dans l'objet "list"
	name = 'name',
	date_creation = 'date_creation',
	date_expiration = 'date_expiration',
	status = 'status',
	signers = 'signers',

	// Éléments dans l'objet "signers" et "subscribers"
	firstName = 'firstName',
	lastName = 'lastName',
	mobilePhone = 'mobilePhone',
	email = 'email',

	subscribers = 'subscribers',
	files = 'files',

	// Éléments dans l'objet "files"
	id = 'id',
	type = 'type',

	cursor = 'cursor',
	firstPage = 'firstPage'
}

export enum enteteTableauAffichageListeEnum {
	name = 'Nom de la procédure',
	date_creation = 'Création',
	date_expiration = 'Expiration',
	status = 'Statut',
	supprimer = 'Supprimer'
}
