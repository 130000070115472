import { filtresListeProceduresFieldsEnum, filtresListeProceduresPostEnum, filtresListeProceduresResponseEnum, statutListeProceduresEnum } from '../enums/listeProceduresEnum'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { fileTypeEnum } from '../../../enum/procedure/fileEnum'
import { typeSignataire } from '../../creationProcedure/types/ajoutSignatairePopinModel'

export interface FiltresListeProceduresFormModel {
	[filtresListeProceduresFieldsEnum.nomProcedure]: string,
	[filtresListeProceduresFieldsEnum.statut]: string
}

export const INITIAL_VALEURS_FORMULAIRE = {
	[filtresListeProceduresFieldsEnum.statut]: getKeyFromEnumValue<statutListeProceduresEnum>(statutListeProceduresEnum, statutListeProceduresEnum.all),
	[filtresListeProceduresFieldsEnum.nomProcedure]: ''
}

export interface FiltresListeProceduresPostModel {
	[filtresListeProceduresPostEnum.seeAll]?: boolean,
	[filtresListeProceduresPostEnum.name]?: string,
	[filtresListeProceduresPostEnum.status]?: string,
	[filtresListeProceduresPostEnum.cursor]?: string
}

export interface SignatairesListeProceduresModel {
	[filtresListeProceduresResponseEnum.id]: string,
	[filtresListeProceduresResponseEnum.firstName]: string,
	[filtresListeProceduresResponseEnum.lastName]: string,
	[filtresListeProceduresResponseEnum.mobilePhone]: string,
	[filtresListeProceduresResponseEnum.email]: string,
	[filtresListeProceduresResponseEnum.status]: string,
	[filtresListeProceduresResponseEnum.type]: typeSignataire
}

export interface SuiveursListeProceduresModel {
	[filtresListeProceduresResponseEnum.email]: string
}

export interface DocumentsListeProceduresModel {
	[filtresListeProceduresResponseEnum.id]: string,
	[filtresListeProceduresResponseEnum.name]: string,
	[filtresListeProceduresResponseEnum.type]: fileTypeEnum.attachment | fileTypeEnum.signable,
}

export interface ListeProceduresResponseModel {
	[filtresListeProceduresResponseEnum.id]: string,
	[filtresListeProceduresResponseEnum.name]: string,
	[filtresListeProceduresResponseEnum.date_creation]: string,
	[filtresListeProceduresResponseEnum.date_expiration]: string,
	[filtresListeProceduresResponseEnum.status]: statutListeProceduresEnum,
	[filtresListeProceduresResponseEnum.signers]: SignatairesListeProceduresModel[],
	[filtresListeProceduresResponseEnum.subscribers]: SuiveursListeProceduresModel[],
	[filtresListeProceduresResponseEnum.files]: DocumentsListeProceduresModel[]
}

export interface FiltresListeProceduresResponseModel {
	[filtresListeProceduresResponseEnum.list]: ListeProceduresResponseModel[],
	[filtresListeProceduresResponseEnum.cursor]: string
	[filtresListeProceduresResponseEnum.firstPage]: boolean
}

export const INITIAL_REPONSE_LISTE_PROCEDURE: FiltresListeProceduresResponseModel = {
	[filtresListeProceduresResponseEnum.list]: [],
	[filtresListeProceduresResponseEnum.cursor]: '',
	[filtresListeProceduresResponseEnum.firstPage]: true
}
