import React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgCalendar = (
	{
		color,
		width = 41,
		height = 31,
		style
	}: IconsModel
) => (
	<svg width={width} height={height} viewBox="0 0 453.54666 453.54666" style={style}>
		<g
			transform="matrix(1.3333333,0,0,-1.3333333,0,453.54667)">
			<g
				transform="scale(0.1)">
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="M 850.395,1988.3 H 587.793 v -262.59 h 100 v 162.59 h 162.602 v 100" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 1488.19,1988.3 h -262.6 v -262.59 h 100 v 162.59 h 162.6 v 100" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 2125.99,1988.3 h -262.6 v -262.59 h 100 v 162.59 h 162.6 v 100" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 2763.78,1988.3 h -262.6 v -262.59 h 100 v 162.59 h 162.6 v 100" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="M 850.395,1563.1 H 587.793 v -262.59 h 100 v 162.59 h 162.602 v 100" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 1488.19,1563.1 h -262.6 v -262.59 h 100 v 162.59 h 162.6 v 100" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 2125.99,1563.1 h -262.6 v -262.59 h 100 v 162.59 h 162.6 v 100" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 2501.18,1300.51 h 100 v 162.59 h 162.6 v 100 h -262.6 v -262.59" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 587.793,875.309 h 100 v 162.601 h 162.602 v 100 H 587.793 V 875.309" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 1225.59,875.309 h 100 v 162.601 h 162.6 v 100 h -262.6 V 875.309" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 1863.39,875.309 h 100 v 162.601 h 162.6 v 100 h -262.6 V 875.309" />
				<path
					fill={color}
					fillOpacity={1}
					fillRule="nonzero"
					stroke="none"
					d="m 3138.97,2307.2 h -162.59 v 0.08 H 262.598 v 325.2 h 537.797 v -162.6 h 100 v 162.6 H 2501.18 v -162.6 h 100 v 162.6 h 537.79 z m 100,425.28 h -637.79 v 162.6 h -100 v -162.6 H 900.395 v 162.6 h -100 v -162.6 H 162.598 V 2207.2 506.488 H 3238.97 V 1938.3 h -100 V 606.488 H 262.598 V 2207.2 H 3238.97 v 525.28" />
			</g>
		</g>
	</svg>
)

export default SvgCalendar
