import { createContext, useState } from 'react'
import { ListeProceduresContextModel } from '../types/listeProceduresModel'
import { FiltresListeProceduresPostModel, FiltresListeProceduresResponseModel, INITIAL_REPONSE_LISTE_PROCEDURE } from '../types/filtresListeProceduresModel'
import { filtresListeProceduresResponseEnum } from '../enums/listeProceduresEnum'

export const ListeProceduresContext = createContext<ListeProceduresContextModel>({
	donneesFormulaire: {},
	setDonneesFormulaire: () => {},
	listeProcedures: INITIAL_REPONSE_LISTE_PROCEDURE,
	setListeProcedures: () => {},
	setCursor: () => {},
	cursor: ''
})

export const ListeProceduresProvider: React.FC = ({ children }) => {
	const [donneesFormulaire, setDonneesFormulaire] = useState<FiltresListeProceduresPostModel>({})
	const [listeProcedures, setListeProcedures] = useState<FiltresListeProceduresResponseModel>(INITIAL_REPONSE_LISTE_PROCEDURE)
	const [cursor, setCursor] = useState<string>(INITIAL_REPONSE_LISTE_PROCEDURE[filtresListeProceduresResponseEnum.cursor])

	return (
		<ListeProceduresContext.Provider
			value={{
				donneesFormulaire,
				setDonneesFormulaire,
				listeProcedures,
				setListeProcedures,
				cursor,
				setCursor
			}}
		>
			{children}
		</ListeProceduresContext.Provider>
	)
}
