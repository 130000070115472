import React from 'react'
import { styled } from '@mui/material'
import ButtonUnstyled from '@mui/base/ButtonUnstyled'
import { buttonTypesEnum } from './enums/buttonTypesEnum'
import { ButtonType } from './types/buttonModel'
import { gecinaBlueMain } from '../../theme'


const CustomButton = styled('button')`
		cursor: pointer;
		border: none;
		font-size: 15px;
		font-weight: 400;
		line-height: 1;
		padding: 15px 15px;
		min-width: 200px;
		font-family: Montserrat;
`

const Button: React.FC<ButtonType> = ({
	 children,
	 type = buttonTypesEnum.button,
	 bgcolor = 'transparent',
	 color = gecinaBlueMain,
	 border = true,
	 onClick,
	 disabled = false,
	 opacity = 1,
	 sx
}) => (
	<ButtonUnstyled
		type={type}
		sx={[
			sx,
			{
				border: border ? `1px solid ${color}` : 'none',
				bgcolor: bgcolor,
				color: color,
				opacity: disabled ? '0.65' : `${opacity}`,
				cursor: disabled ? 'default' : 'pointer'
			}
		]}
		component={CustomButton}
		onClick={onClick}
		disabled={disabled}>
		{children}
	</ButtonUnstyled>
)

export default Button
