import React from 'react'
import { Box, CircularProgress, SxProps } from '@mui/material'
import { jsx } from '@emotion/react'

interface AcceptingProps {
	size?: number | string,
	sx?: SxProps
}

const Loader: React.FC<AcceptingProps> = (
	{
		size,
		sx
	}
) => {

	return (
		<Box
			sx={[
				{
					width: '100%',
					height: '100%',
					marginBottom: '20px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					'& div': {
						width: '100px !important',
						height: '100px !important'
					}
				},
				...(Array.isArray(sx) ? sx : [sx])
			]}
		>
			<CircularProgress size={size} />
		</Box>
	)
}

export default Loader