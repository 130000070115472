import React, { useCallback, useContext, useMemo, useState } from 'react'
import { Box, Grid, IconButton } from '@mui/material'
import CustomTable from '../../../components/table/Table'
import { enteteTableauAffichageListeEnum, filtresListeProceduresPostEnum, filtresListeProceduresResponseEnum, statutListeProceduresEnum } from '../enums/listeProceduresEnum'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { ListeProceduresContext } from '../context/ListeProceduresContext'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { ListeProceduresResponseModel } from '../types/filtresListeProceduresModel'
import { HeadRow } from '../../../components/table/types/tableModel'
import { tableTypeEnum } from '../../../components/table/enums/tableTypeEnum'
import { getListeProcedure } from '../api/listeProcedureAPI'
import { useNavigate } from 'react-router-dom'
import liens from '../../../enum/liens'
import { DeleteForever, FirstPage, NavigateNext } from '@mui/icons-material'
import { gecinaBlueMain } from '../../../theme'
import PopinSuppressionProcedure from './popinSuppressionProcedure'
import { INITIAL_SUPPRESSION_PROCEDUREE_POPIN, PopinSuppressionModel } from '../types/popinSuppressionModel'

const POPIN_SUPPRESSION_ID = 'suppression_procedure'

const AffichageListe = () => {
	const { cursor, listeProcedures, donneesFormulaire, setListeProcedures, setDonneesFormulaire, setCursor } = useContext(ListeProceduresContext)
	const navigate = useNavigate()
	const [popinSuppressionOuverte, setPopinSuppressionOuverte] = useState<PopinSuppressionModel>(INITIAL_SUPPRESSION_PROCEDUREE_POPIN)

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: enteteTableauAffichageListeEnum.name,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.name)
				},
				{
					label: enteteTableauAffichageListeEnum.date_creation,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.date_creation),
					type: tableTypeEnum.date
				},
				{
					label: enteteTableauAffichageListeEnum.date_expiration,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.date_expiration),
					type: tableTypeEnum.date
				},
				{
					label: enteteTableauAffichageListeEnum.status,
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.status)
				},
				{
					label: '',
					id: getKeyFromEnumValue<enteteTableauAffichageListeEnum>(enteteTableauAffichageListeEnum, enteteTableauAffichageListeEnum.supprimer),
					getFullItem: true,
					size: '8%',
					render: (procedure: ListeProceduresResponseModel) => {
						return procedure[filtresListeProceduresResponseEnum.status] === statutListeProceduresEnum.draft ?
							<IconButton
								sx={{
									padding: 0,
									color: gecinaBlueMain
								}}
								onClick={(event) => {
									//Permet d'empecher le onClick de la ligne qui ouvre la page detail
									event.preventDefault()
									event.stopPropagation()
									setPopinSuppressionOuverte({ open: true, id: POPIN_SUPPRESSION_ID, procedureId: procedure[filtresListeProceduresResponseEnum.id] })
								}}
							>
								<DeleteForever
									sx={{
										height: 40,
										width: 40
									}}
								/>
							</IconButton>
							:
							<></>
					}
				}
			]
		), []
	)

	const auChangementDePage = (
		(reset: boolean) => {
			const data = {
				...donneesFormulaire,
				[filtresListeProceduresPostEnum.cursor]: reset ? '' : cursor,
				[filtresListeProceduresPostEnum.seeAll]: donneesFormulaire[filtresListeProceduresPostEnum.seeAll] ?? false
			}

			getListeProcedure(data)
				.then((response) => {
					setListeProcedures({
						...response,
						[filtresListeProceduresResponseEnum.firstPage]: reset
					})
					setDonneesFormulaire(data)
					setCursor(response[filtresListeProceduresPostEnum.cursor])
				})
				.finally(() => window.scrollTo(0, 0))
		}
	)

	const auCliqueSurLigne = useCallback(
		(procedureSelectionnee: ListeProceduresResponseModel) => {
			const procedure = listeProcedures[filtresListeProceduresResponseEnum.list].filter((listeProcedure) => listeProcedure[filtresListeProceduresResponseEnum.name] === procedureSelectionnee[filtresListeProceduresResponseEnum.name])[0]

			navigate(liens.detailProcedure, { state: { procedure } })
		}, [listeProcedures]
	)

	if (listeProcedures[filtresListeProceduresResponseEnum.list].length === 0) {
		return <></>
	}

	return (
		<Box sx={{ paddingX: '172px' }}>
			<CustomTable
				id="listeProcedures"
				headRows={entetesTableau}
				rows={remplissageLigneSelonEnteteTableau<ListeProceduresResponseModel>(listeProcedures[filtresListeProceduresResponseEnum.list], entetesTableau)}
				auCliqueSurLigne={auCliqueSurLigne}
				hasSort={false}
				hasPagination={false}
				borderBottom
			/>
			<Grid justifySelf="center">
				<IconButton
					disabled={listeProcedures[filtresListeProceduresResponseEnum.firstPage]}
					sx={{
						color: gecinaBlueMain
					}}
					onClick={() => {
						setCursor('')
						auChangementDePage(true)
					}}
				>
					<FirstPage
						sx={{
							height: 40,
							width: 40
						}}
					/>
				</IconButton>
				<IconButton
					disabled={cursor === ''}
					sx={{
						color: gecinaBlueMain
					}}
					onClick={() => auChangementDePage(false)}
				>
					<NavigateNext
						sx={{
							height: 40,
							width: 40
						}}
					/>
				</IconButton>
			</Grid>
			<PopinSuppressionProcedure
				open={popinSuppressionOuverte.open}
				onCloseDialog={
					() => {
						setPopinSuppressionOuverte(INITIAL_SUPPRESSION_PROCEDUREE_POPIN)
						//refresh
						navigate(0)
					}
				}
				procedureId={popinSuppressionOuverte.procedureId} />
		</Box>
	)
}

export default AffichageListe
