import React, { useState } from 'react'
import './../App.css'
import { Route, Routes, useLocation } from 'react-router-dom'
import ListeProcedures from '../modules/listeProcedures/ListeProcedures'
import DetailProcedure from '../modules/detailProcedure/DetailProcedure'
import NavBar from '../components/navBar/navBar'
import CreationProcedure from '../modules/creationProcedure/CreationProcedure'
import liens from '../enum/liens'
import { useQuery } from 'react-query'
import { fetchToken } from './api/authentificationApi'
import axios from 'axios'
import localStorageEnum from '../enum/localstorageEnum'
import HttpStatusCode from '../enum/httpStatusCode'
import Loader from '../components/loader/Loader'

function App() {
	const location = useLocation()
	//Permet de réinitialiser le croll à chaque changement de page
	React.useEffect(() => {
		window.scrollTo(0, 0)
	}, [location])

	axios.interceptors.request.use(function (config) {
		config.headers && (config.headers['Authorization'] = `Bearer ${localStorage.getItem(localStorageEnum.accessToken)}`)
		return config
	})

	axios.interceptors.response.use((response) => response, (error) => {
		if (error.response.status === HttpStatusCode.UNAUTHORIZED) {
			window.location.pathname = liens.login
		}

		return Promise.reject(error)
	})

	const nomParDefaut = JSON.stringify({ name: '' })

	const [nomUtilisateur, setNomUtilisateur] = useState<string>('')
	const { status } = useQuery('session', fetchToken,
		{
			refetchInterval: 900000
		}
	)

	if (nomUtilisateur === '' && localStorage.getItem(localStorageEnum.informationsUtilisateur) !== null) {
		setNomUtilisateur(JSON.parse(localStorage.getItem(localStorageEnum.informationsUtilisateur) || nomParDefaut).name)
	}

	if (status === 'loading' || nomUtilisateur === '') {
		return <Loader/>
	}

	return (
		<div style={{ height: '100%' }}>
			<Routes>
				<Route path="/*" element={<NavBar name={nomUtilisateur} />} />
			</Routes>
			<Routes>
				<Route path={liens.listeProcedure} element={<ListeProcedures />} />
				<Route path={liens.detailProcedure} element={<DetailProcedure />} />
				<Route path={liens.creationProcedure} element={<CreationProcedure />} />
				<Route path="/*" element={<ListeProcedures />} />
			</Routes>
		</div>
	)
}

export default App
