import React, { Dispatch, useContext, useEffect } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { FiltresListeProceduresFormModel, FiltresListeProceduresPostModel, INITIAL_VALEURS_FORMULAIRE } from '../types/filtresListeProceduresModel'
import TextField from '../../../components/fields/FormTextField'
import { filtresListeProceduresFieldsEnum, filtresListeProceduresPostEnum, filtresListeProceduresResponseEnum, statutListeProceduresEnum } from '../enums/listeProceduresEnum'
import { Grid, MenuItem } from '@mui/material'
import Button from '../../../components/button/Button'
import { fontFamilyMonserrat, gecinaBrownMain, WHITE } from '../../../theme'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { mapperBackVersListeProcedures, mapperListeProceduresVersBack } from '../utils/filtresListeProceduresUtils'
import { getListeProcedure } from '../api/listeProcedureAPI'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { ListeProceduresContext } from '../context/ListeProceduresContext'
import FormSelectField from '../../../components/fields/FormSelectField'

interface FiltresListeProps {
	setLoading: Dispatch<React.SetStateAction<boolean>>,
	rafraichir: boolean
}

const FiltresListeProcedures: React.FC<FiltresListeProps> = (
	{
		setLoading,
		rafraichir
	}
) => {
	const { setCursor, cursor, setListeProcedures, donneesFormulaire, setDonneesFormulaire } = useContext(ListeProceduresContext)

	const methods = useForm<FiltresListeProceduresFormModel>({
		defaultValues: mapperBackVersListeProcedures(donneesFormulaire)
	})
	const { register, handleSubmit, reset, getValues } = methods

	useEffect(() => {
		//Ne charge la liste uniquement si nous n'avons pas déjà des données dans le formulaire
		if ((!(filtresListeProceduresPostEnum.cursor in donneesFormulaire)) || rafraichir) {
			setTimeout(() => onSubmit(getValues()), 1000)
		}
	}, [])

	const onSubmit: SubmitHandler<FiltresListeProceduresFormModel> = (values) => {
		setLoading(true)
		// Mapping des valeurs du formulaire pour matcher avec le back + gestion du switch "seeAll"
		const data: FiltresListeProceduresPostModel = {
			...mapperListeProceduresVersBack(values, cursor),
			[filtresListeProceduresPostEnum.seeAll]: donneesFormulaire[filtresListeProceduresPostEnum.seeAll] ?? false
		}

		// Récupération de la liste des procédures selon les valeurs du filtre
		return getListeProcedure(data)
			.then((response) => {
				setListeProcedures({
					...response,
					[filtresListeProceduresResponseEnum.firstPage]: true
				})
				setDonneesFormulaire(data)
				setCursor(response[filtresListeProceduresPostEnum.cursor] || '')
				setLoading(false)
			})
	}

	return (
		<FormProvider {...methods}>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Grid container spacing={2}>
					{/* Champ pour le nom de la procédure */}
					<Grid item xs>
						<TextField
							register={register(filtresListeProceduresFieldsEnum.nomProcedure)}
							id={filtresListeProceduresFieldsEnum.nomProcedure}
							label="Nom de la procédure"
							placeholder="Saisir une partie du nom"
						/>
					</Grid>

					{/* Champ pour le statut de la procédure */}
					<Grid item xs>
						<FormSelectField
							register={register(filtresListeProceduresFieldsEnum.statut)}
							id={filtresListeProceduresFieldsEnum.statut}
							label="Statut"
						>
							{
								Object.keys(statutListeProceduresEnum).map(
									(statut) => (
										<MenuItem value={statut} key={statut}>
											{statutListeProceduresEnum[statut as keyof typeof statutListeProceduresEnum]}
										</MenuItem>
									)
								)
							}
						</FormSelectField>
					</Grid>

					{/* Partie comprenant le bouton de recherche et le bouton de reset des filtres */}
					<Grid container item xs spacing={2} sx={{ m: 0 }} alignContent="flex-start">
						<span
							style={{
								width: '100%',
								paddingRight: 15,
								paddingTop: 7,
								fontSize: 14,
								marginLeft: 0,
								boxSizing: 'border-box',
								textAlign: 'left',
								fontFamily: fontFamilyMonserrat,
								fontWeight: 500
							}}
						> </span>

						<Grid item xs={9} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: '32px',
									p: 0,
									minWidth: '100%',
									fontWeight: 600
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
							>
								Filtrer
							</Button>
						</Grid>

						<Grid item xs={3} sx={{ pt: '0px !important', height: 'fit-content' }}>
							<Button
								sx={{
									height: 32,
									p: 0,
									minWidth: '100%'
								}}
								bgcolor={gecinaBrownMain}
								color={WHITE}
								border={false}
								disabled={false}
								type={buttonTypesEnum.submit}
								onClick={() => reset(INITIAL_VALEURS_FORMULAIRE)}
							>
								<AutorenewIcon />
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</FormProvider>
	)
}

export default FiltresListeProcedures
