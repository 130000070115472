import { DialogCommonProps } from '../../../components/dialog/types/dialogModel'
import Dialog from '../../../components/dialog/Dialog'
import { Box, Grid } from '@mui/material'
import React, { useState } from 'react'
import SvgClose from '../../../icons/SvgClose'
import { gecinaBlueMain, gecinaBrownMain, WHITE } from '../../../theme'
import Button from '../../../components/button/Button'
import { buttonTypesEnum } from '../../../components/button/enums/buttonTypesEnum'
import { deleteProcedure } from '../api/listeProcedureAPI'
import HttpStatusCode from '../../../enum/httpStatusCode'

interface AcceptingProps {
	procedureId: string
}

type PopinSuppressionProcedureProps = AcceptingProps & DialogCommonProps

const PopinSuppressionProcedure: React.FC<PopinSuppressionProcedureProps> = ({ onCloseDialog, open, procedureId }) => {
	const [bouttonDisabled, setBouttonDisabled] = useState<boolean>(false)
	const [texte, setTexte] = useState<string>('La validation entraînera une suppression définitive de la procédure')

	const supprimerProcedure = () => {
		setBouttonDisabled(true)
		deleteProcedure(procedureId)
			.then((statusCode) => {
				if (statusCode === HttpStatusCode.NO_CONTENT) {
					setTexte('La suppression a bien été effectué')
					onCloseDialog()
				}
			})
			.catch((response) => {
				setTexte(response.response.data)
			})
	}

	return (
		<Dialog
			onCloseDialog={onCloseDialog}
			open={open && procedureId !== ''}
			fullScreen={false}
			withPadding={false}
			maxWidth={false}
		>
			<Box sx={{ width: 785, height: 'fit-content', ml: '40px', mr: '40px' }}>
				<Grid container sx={{
					height: 65,
					mt: '40px',
					mb: '20px',
					'&::after': {
						top: '110px',
						width: '90%',
						height: '1px',
						content: '\' \'',
						display: 'inline-block',
						background: '#C4C4C4',
						position: 'absolute',
						right: '50px'
					}
				}}>
					<Box sx={{
						width: 681,
						height: 25,
						lineHeight: '25px',
						color: '#122941',
						flex: 'none',
						order: 0,
						flexGrow: 1,
						fontFamily: 'BlackerDisplay', fontSize: 22, fontWeight: 900
					}}>
						Suppression de la procédure de signature
					</Box>
					<Box sx={{
						height: 25,
						flex: 'none',
						order: 1,
						flexGrow: 1,
						cursor: 'pointer'
					}} onClick={onCloseDialog}>
						<SvgClose color={gecinaBlueMain} />
					</Box>
				</Grid>
				<Grid container item xs={12} justifyContent="space-evenly" sx={{ height: 'fit-content', mt: '20px', mb: '20px' }}>
					<Box sx={{
						color: '#122941',
						fontFamily: 'Montserrat',
						fontSize: 18,
						height: 'fit-content'
					}}>
						{texte}
					</Box>
				</Grid>
				<Grid container item xs justifyContent="flex-end" sx={{ mt: '50px', ml: 0 }} spacing={2}>
					<Grid item sx={{ maxWidth: 'max-content', minWidth: 'fit-content' }}>
						<Button
							sx={{
								height: '51px',
								p: 0,
								width: '195px',
								fontWeight: 600,
								lineHeight: '18.29px'
							}}
							bgcolor={gecinaBlueMain}
							color={gecinaBrownMain}
							border={false}
							disabled={bouttonDisabled}
							type={buttonTypesEnum.button}
							onClick={() => supprimerProcedure()}
						>
							Supprimer
						</Button>
					</Grid>
					<Grid item sx={{ maxWidth: 'max-content', ml: '24px', minWidth: 'fit-content' }}>
						<Button
							sx={{
								height: '51px',
								p: 0,
								width: '195px',
								fontWeight: 600,
								lineHeight: '18.29px'
							}}
							bgcolor={WHITE}
							color={gecinaBlueMain}
							border={true}
							disabled={false}
							type={buttonTypesEnum.button}
							onClick={onCloseDialog}
						>
							Fermer
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Dialog>
	)
}

export default PopinSuppressionProcedure