import React, { useEffect, useState } from 'react'
import VueProcedureAriane from '../../components/ariane/VueProcedureAriane'
import { useLocation, useNavigate } from 'react-router-dom'
import { ListeProceduresResponseModel } from '../listeProcedures/types/filtresListeProceduresModel'
import liens from '../../enum/liens'
import { filtresListeProceduresResponseEnum } from '../listeProcedures/enums/listeProceduresEnum'
import { Box, Grid } from '@mui/material'
import DetailSignataires from './components/DetailSignataires'
import DetailSuiveurs from './components/DetailSuiveurs'
import DetailDocuments from './components/DetailDocuments'
import { getAdditionalInfosProcedure } from './api/detailProcedureApi'
import Loader from '../../components/loader/Loader'
import { gecinaBlueMain } from '../../theme'

interface DetailProcedureState {
	procedure: ListeProceduresResponseModel
}

const DetailProcedure = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const [ isLoading, setIsLoading ] = useState<boolean>(false)
	const [ procedure, setProcedure ] = useState<ListeProceduresResponseModel | null>(
		location.state
			? (location.state as DetailProcedureState).procedure
			: null
	)
	const [ additionalInformationError, setAdditionalInformationError ] = useState<string | null>(null)


	useEffect(() => {
		if (!!procedure) {
			setIsLoading(true)
			getAdditionalInfosProcedure(procedure)
				.then((data) => {
					setProcedure(data)
				})
				.catch((error) => {
					setAdditionalInformationError('Une erreur est survenue lors de la récupération des informations de la procédure : ' + error.response.data)
				})
				.finally(() => setIsLoading(false))
		}
	}, [])

	if (!procedure) {
		navigate(liens.listeProcedure, { state: { rafraichir: true } })

		return <></>
	}

	if (isLoading) {
		return <Loader/>
	}

	return (
		<>
			<VueProcedureAriane procedure={procedure} />

			{
				!!additionalInformationError
					? (
						<Box sx={{ paddingTop: '22px', fontFamily: 'BlackerDisplay', fontSize: 20, fontWeight: 900, color: gecinaBlueMain }}>
							{additionalInformationError}
						</Box>
					)
					: (
						<Grid container sx={{ paddingX: '172px' }}>
							{/* Tableau récapitulant les signataires, masqué si pas de signataire */}
							{
								!!procedure[filtresListeProceduresResponseEnum.signers] && procedure[filtresListeProceduresResponseEnum.signers].length > 0 && (
									<DetailSignataires procedureId={procedure[filtresListeProceduresResponseEnum.id]} signataires={procedure[filtresListeProceduresResponseEnum.signers]} />
								)
							}

							{/* Tableau récapitulant les suiveurs, masqué si pas de suiveur */}
							{
								!!procedure[filtresListeProceduresResponseEnum.subscribers] && procedure[filtresListeProceduresResponseEnum.subscribers].length > 0 && (
									<DetailSuiveurs suiveurs={procedure[filtresListeProceduresResponseEnum.subscribers]} />
								)
							}

							{/* Tableau récapitulant les documents, masqué si pas de document */}
							{
								!!procedure[filtresListeProceduresResponseEnum.files] && procedure[filtresListeProceduresResponseEnum.files].length > 0 && (
									<DetailDocuments procedureId={procedure[filtresListeProceduresResponseEnum.id]} documents={procedure[filtresListeProceduresResponseEnum.files]} />
								)
							}
						</Grid>
					)
			}
		</>
	)
}

export default DetailProcedure
