import React, { useCallback, useMemo, useState } from 'react'
import { remplissageLigneSelonEnteteTableau } from '../../../components/table/utils/tableUtils'
import { DocumentsListeProceduresModel } from '../../listeProcedures/types/filtresListeProceduresModel'
import CustomTable from '../../../components/table/Table'
import { HeadRow } from '../../../components/table/types/tableModel'
import { getKeyFromEnumValue } from '../../../utils/utils'
import { enteteTableauDetailDocumentsEnum } from '../enums/detailProcedureEnum'
import { Box } from '@mui/material'
import SvgDownload from '../../../icons/SvgDownload'
import { gecinaBrownMain } from '../../../theme'
import axios from 'axios'
import liens from '../../../enum/liens'
import Loader from '../../../components/loader/Loader'
import { filtresListeProceduresResponseEnum } from '../../listeProcedures/enums/listeProceduresEnum'
import { fileTypeEnum } from '../../../enum/procedure/fileEnum'
import { downloadBlob } from '../../../utils/fileUtils'
import { FetchMethodEnum, FetchResponseType } from '../../../enum/fetchMethodEnum'

interface AcceptingProps {
	documents: DocumentsListeProceduresModel[]
	procedureId: string
}

type DetailDocumentsProps = AcceptingProps
const DetailDocuments: React.FC<DetailDocumentsProps> = (
	{
		procedureId,
		documents
	}
) => {
	const telechargementDocument = useCallback(
		// Call symfony pour récup le fichier
		(documentId: string) => {
			setLoadingDocs([...loadingDocs, documentId])
			axios({
				url: `${liens.telechargementFichier}/${procedureId}/${documentId}`,
				method: FetchMethodEnum.get,
				responseType: FetchResponseType.blob // important
			}).then((response) => {
				const suggestedFileName = documents.filter((doc) => doc[filtresListeProceduresResponseEnum.id] === documentId)[0][filtresListeProceduresResponseEnum.name]
				downloadBlob(response.data, suggestedFileName)
			})
				.finally(() => setLoadingDocs(loadingDocs.filter((id) => id !== documentId)))
		}, []
	)
	const [loadingDocs, setLoadingDocs] = useState<string[]>([])

	const entetesTableau: HeadRow[] = useMemo(
		() => (
			[
				{
					label: '',
					id: getKeyFromEnumValue<enteteTableauDetailDocumentsEnum>(enteteTableauDetailDocumentsEnum, enteteTableauDetailDocumentsEnum.name)
				},
				{
					label: '',
					id: getKeyFromEnumValue<enteteTableauDetailDocumentsEnum>(enteteTableauDetailDocumentsEnum, enteteTableauDetailDocumentsEnum.id),
					size: '8%',
					render: (documentId: string) => (
						<Box
							component="div"
							sx={{
								width: 'fit-content',
								'& svg': {
									marginLeft: 0
								}
							}}
						>
							{(loadingDocs.filter((id) => id === documentId).length > 0) ?
								<Loader size={30} sx={{ paddingBottom: '10px', mb: '0px' }} />
								:
								<SvgDownload color={gecinaBrownMain} width={37} height={36} style={{ cursor: 'pointer' }} onClick={() => telechargementDocument(documentId)} />
							}
						</Box>
					)
				}
			]
		), [loadingDocs]
	)

	const afficherTableDocuments = (fileType: string, title: string) =>
		<CustomTable
			id={`documents_${fileType}`}
			titre={title}
			headRows={entetesTableau}
			rows={remplissageLigneSelonEnteteTableau<DocumentsListeProceduresModel>(documents.filter((doc) => doc[filtresListeProceduresResponseEnum.type] === fileType), entetesTableau)}
			hasPagination={false}
			hideHeaders
		/>

	return (
		<>
			{afficherTableDocuments(fileTypeEnum.signable, 'Documents à signer')}
			{afficherTableDocuments(fileTypeEnum.attachment, 'Documents en consultation seule')}
		</>
	)
}

export default DetailDocuments
