import * as React from 'react'
import { IconsModel } from './types/iconsModel'

const SvgProfile = (
	{
		color,
		width = 24,
		height = 24,
		...props
	}: IconsModel
) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" {...props}>
		<path fill={color} fillRule="evenodd"
		      d="M23.52 12C23.52 5.648 18.352.48 12 .48 5.648.48.48 5.648.48 12c0 1.728.373 3.389 1.109 4.938l.676-.322c-.687-1.447-1.036-3-1.036-4.616C1.229 6.06 6.06 1.229 12 1.229c5.94 0 10.771 4.832 10.771 10.771 0 5.472-4.102 10.005-9.393 10.683l-.015.002c-.077.01-.154.019-.232.027l-.076.007-.192.018-.121.008-.16.01-.169.007-.118.005c-.098.003-.197.004-.295.004-3.183 0-6.142-1.372-8.195-3.781.377-.154.902-.363 1.479-.592 1.525-.606 3.423-1.36 4.124-1.711 1.072-.536 1.053-1.097 1.025-1.874-.004-.141-.01-.287-.01-.451 0-.298-.197-.633-.47-1.098-.32-.542-.716-1.217-.828-1.908l-.05-.315h-.32c-.198 0-.421-.171-.421-.262V9.61c0-.097.045-.262.215-.262h.341l.04-.435c.234-2.486.388-4.13 3.07-4.13s2.836 1.644 3.07 4.13l.04.435h.34c.17 0 .216.165.216.262v1.168c0 .091-.223.262-.421.262h-.32l-.05.315c-.112.69-.509 1.366-.828 1.908-.273.465-.47.8-.47 1.098 0 .164-.006.31-.01.451-.028.777-.047 1.338 1.025 1.874.698.349 1.675.737 2.91 1.228l.728.29.278-.696-.729-.29c-1.22-.484-2.182-.867-2.852-1.202-.642-.321-.637-.469-.612-1.178.005-.14.01-.3.01-.475.009-.11.216-.463.368-.72.296-.503.689-1.172.866-1.898.449-.124.856-.497.856-.967V9.61c0-.447-.25-.809-.617-.949C15.57 6.258 15.28 4.036 12 4.036S8.43 6.258 8.202 8.662c-.368.14-.617.502-.617.95v1.167c0 .47.407.843.856.967.177.726.57 1.395.866 1.898.152.257.36.61.367.718 0 .177.006.336.01.477.026.71.031.857-.611 1.178-.672.336-2.554 1.084-4.066 1.685-.883.35-1.645.654-1.96.794l-.438.194.294.378C5.104 21.898 8.42 23.52 12 23.52c.105 0 .21-.002.315-.004l.128-.005.18-.008.17-.01.13-.01.207-.018.08-.008c.082-.009.165-.018.248-.029l.015-.002c3.006-.381 5.728-1.922 7.624-4.358l-.004-.003c1.52-1.952 2.427-4.405 2.427-7.065" />
	</svg>

)

export default SvgProfile