import { champsSignataireEnum, champsSuiveurEnum } from '../components/popinAjoutSignataire/enums/champsSignataireEnum'
import { fileTypeEnum } from '../../../enum/procedure/fileEnum'
import { typeSignataire } from './ajoutSignatairePopinModel'

export const INITIAL_CREATION_PROCEDURE: CreationProcedureModel = {
	signersValidators: [],
	subscribers: [],
	name: '',
	expirationDate: null,
	uuid: '',
	signable_files: [],
	attachment_files: []
}

export interface FetchUUIDModel {
	uuid: string
}

export interface SignataireValidateurModel {
	[champsSignataireEnum.nom]: string,
	[champsSignataireEnum.prenom]: string,
	[champsSignataireEnum.telephone]: string,
	[champsSignataireEnum.courriel]: string,
	[champsSignataireEnum.type]: typeSignataire
}

export interface SignataireRechercheModel {
	[champsSignataireEnum.recherche]: string,
	[champsSignataireEnum.nom]: string,
	[champsSignataireEnum.prenom]: string,
	[champsSignataireEnum.telephone]: string,
	[champsSignataireEnum.courriel]: string,
	[champsSignataireEnum.type]: typeSignataire
}

export interface SignataireValidateurListeModel {
	[champsSignataireEnum.nom]: string,
	[champsSignataireEnum.prenom]: string,
	[champsSignataireEnum.telephone]: string,
	[champsSignataireEnum.courriel]: string,
	[champsSignataireEnum.type]: typeSignataire,
	ordre: number,
	supprimer: number,
}

export interface SuiveurModel {
	[champsSuiveurEnum.nom]: string,
	[champsSuiveurEnum.prenom]: string,
	[champsSuiveurEnum.courriel]: string
}

export interface SuiveurRechercheModel {
	[champsSuiveurEnum.recherche]: string,
	[champsSuiveurEnum.nom]: string,
	[champsSuiveurEnum.prenom]: string,
	[champsSuiveurEnum.courriel]: string
}

export interface SuiveurListeModel {
	[champsSuiveurEnum.nom]: string,
	[champsSuiveurEnum.prenom]: string,
	[champsSuiveurEnum.courriel]: string,
	supprimer: number
}

export interface CreationProcedureModel {
	signersValidators: SignataireValidateurModel[],
	subscribers: SuiveurModel[],
	name: string,
	expirationDate: Date | null
	uuid: string,
	signable_files: string[],
	attachment_files: string[]
}

export interface AjoutFichierBackModel {
	name: string,
	fileData: string,
	uuid: string,
	type: fileTypeEnum.attachment | fileTypeEnum.signable
}

export interface SuppressionFichierBackModel {
	name: string,
	uuid: string,
	type: fileTypeEnum.attachment | fileTypeEnum.signable
}

export interface CreationProcedureContextModel {
	setFormValues: (formValues: CreationProcedureModel) => void
	formValues: CreationProcedureModel
}
