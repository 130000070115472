import React from 'react'
import { gecinaErrorColor, WHITE } from '../../theme'
import { Box } from '@mui/material'

interface AcceptingProps {
	errorWhite?: boolean
}

const ErrorComponent: React.FC<AcceptingProps> = (
	{
		children,
		errorWhite = false
	}
) => (
	<Box
		sx={{
			color: errorWhite ? WHITE : gecinaErrorColor,
			fontSize: 12,
			width: '100%',
			textAlign: 'left'
		}}
	>
		{children}
	</Box>
)

export default ErrorComponent
