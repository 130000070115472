import { FiltresListeProceduresFormModel, FiltresListeProceduresPostModel, INITIAL_VALEURS_FORMULAIRE } from '../types/filtresListeProceduresModel'
import { filtresListeProceduresFieldsEnum, filtresListeProceduresPostEnum } from '../enums/listeProceduresEnum'
import { formatDate } from '../../../utils/formatDate'

export const mapperListeProceduresVersBack = (values: FiltresListeProceduresFormModel, cursor: string): FiltresListeProceduresPostModel => (
	{
		[filtresListeProceduresPostEnum.name]: values[filtresListeProceduresFieldsEnum.nomProcedure].length > 0 ? values[filtresListeProceduresFieldsEnum.nomProcedure] : undefined,
		[filtresListeProceduresPostEnum.status]: values[filtresListeProceduresFieldsEnum.statut].length > 0 ? values[filtresListeProceduresFieldsEnum.statut] : undefined,
		[filtresListeProceduresPostEnum.cursor]: cursor
	}
)

export const mapperBackVersListeProcedures = (values: FiltresListeProceduresPostModel): FiltresListeProceduresFormModel => (
	{
		[filtresListeProceduresFieldsEnum.nomProcedure]: values && values[filtresListeProceduresPostEnum.name] && values[filtresListeProceduresPostEnum.name]!.length > 0 ? (values[filtresListeProceduresPostEnum.name] as string) : INITIAL_VALEURS_FORMULAIRE[filtresListeProceduresFieldsEnum.nomProcedure],
		[filtresListeProceduresFieldsEnum.statut]: values && values[filtresListeProceduresPostEnum.status] && values[filtresListeProceduresPostEnum.status]!.length > 0 ? (values[filtresListeProceduresPostEnum.status] as string) : INITIAL_VALEURS_FORMULAIRE[filtresListeProceduresFieldsEnum.statut]
	}
)
